export const LANDING = "/";
export const STAFFLOGIN = "/stafflogin";
export const ABOUT = "/about";
export const PUBLICDATA = "/publicdata";
export const CONTACT = "/contact";
export const HOME = "/home";
export const ADMIN = "/admin";
export const MONITOR = "/mc";
export const HOMEDETAILS = "/home/sensors/:sensorId";
export const PASSWORDFORGET = "/passforget";
export const PASSWORDCHANGE = "/passchange";
export const TESTER = "/tester";
